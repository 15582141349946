import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginSection.css"; // Ensure your CSS file is linked
import footerImage from "../../assets/Login/loginAgent.png";
import GradientButton from "../Button/GradientButton";
import { useAuthStore } from "../../store/useAuthStore";

const LoginSection = () => {
  const [activeTab, setActiveTab] = useState("login");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); // For registration
  const [lastName, setLastName] = useState(""); // For registration
  const [confirmPassword, setConfirmPassword] = useState(""); // For registration
  const [referralCode, setReferralCode] = useState(""); // For registration
  const [isDisabled, setIsDisabled] = useState(true); // Disable login button initially
  const [registerDisabled, setRegisterDisabled] = useState(true); // Disable register button initially
  const [localError, setLocalError] = useState(null); // Local error state for registration

  const navigate = useNavigate();
  const {
    login,
    isAuthenticated,
    error,
    loginLoading,
    register,
    registerLoading,
  } = useAuthStore();
  const defaultReferralCode = process.env.REACT_APP_DEFAULT_REFERRAL; // Get default referral code from .env

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/race");
    }
  }, [isAuthenticated, navigate]);

  // Disable login button if fields are empty
  useEffect(() => {
    setIsDisabled(mobileNumber === "" || password === "");
  }, [mobileNumber, password]);

  // Disable register button if fields are invalid
  useEffect(() => {
    setRegisterDisabled(
      firstName === "" ||
        lastName === "" ||
        mobileNumber.length !== 11 || // Ensure mobile number is exactly 11 digits
        password === "" ||
        password !== confirmPassword
    );
  }, [firstName, lastName, mobileNumber, password, confirmPassword]);

  // Handles tab switching between login and register
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setLocalError(null); // Clear local error when switching tabs
  };

  // Validate mobile number input to accept only numbers and limit to 11 digits
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,11}$/.test(value)) {
      // Accept only digits, max length 11
      setMobileNumber(value);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(mobileNumber, password);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setLocalError("Passwords do not match.");
      return;
    }
    const finalReferralCode = referralCode || defaultReferralCode; // Use default referral code if empty
    await register(
      firstName,
      lastName,
      mobileNumber,
      password,
      finalReferralCode
    );
  };

  return (
    <div className="login-section">
      <div className="login-register-tabs">
        <div
          className={`tab ${activeTab === "login" ? "active" : ""}`}
          onClick={() => handleTabClick("login")}
        >
          Login
        </div>

        <div
          className={`tab ${activeTab === "register" ? "active" : ""}`}
          onClick={() => handleTabClick("register")}
        >
          Register
        </div>
      </div>

      <div className="form-container">
        {/* Login Form */}
        {activeTab === "login" && (
          <form className="login-form" onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Mobile number*"
              className="input-field"
              value={mobileNumber}
              onChange={handleMobileNumberChange} // Handle only number input and max length of 11
            />
            <input
              type="password"
              placeholder="Password*"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <div className="error-message">
                {error} {/* Display the error message from the store */}
              </div>
            )}
            <GradientButton
              style={{ marginTop: "8px", marginBottom: "16px" }}
              text={loginLoading ? "Loading..." : "LOGIN"} // Show loading state
              onClick={handleLogin}
              disabled={isDisabled || loginLoading} // Disable button while loading or if inputs are empty
            />
            <a href="/forgot-password" className="forgot-password">
              Forgot password?
            </a>
          </form>
        )}

        {/* Register Form */}
        {activeTab === "register" && (
          <form className="register-form" onSubmit={handleRegister}>
            <input
              type="text"
              placeholder="First Name*"
              className="input-field"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name*"
              className="input-field"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Mobile number*"
              className="input-field"
              value={mobileNumber}
              onChange={handleMobileNumberChange} // Handle only number input and max length of 11
            />
            <input
              type="password"
              placeholder="Password*"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password*"
              className="input-field"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              type="text"
              placeholder="Referral Code (Optional)"
              className="input-field"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            {(error || localError) && (
              <div className="error-message">
                {localError || error}{" "}
                {/* Display the local or store error message */}
              </div>
            )}
            <GradientButton
              style={{
                marginTop: "8px",
                marginBottom: "16px",
                background: registerDisabled
                  ? "#5B5B5B"
                  : "linear-gradient(90deg, #FFF61F 27%, #7BF342 100%)",
                color: registerDisabled ? "#9F9F9F" : undefined,
                cursor: registerDisabled ? "not-allowed" : "pointer",
              }}
              text={registerLoading ? "Registering..." : "REGISTER"} // Show loading state
              onClick={handleRegister}
              disabled={registerDisabled || registerLoading} // Disable button while loading or if fields are invalid
            />
          </form>
        )}
      </div>

      {/* <img src={footerImage} alt="Become an Agent" className="agent-image" /> */}
      <footer className="footer-text">
        <p>Copyright 2024. www.KareraKing.com All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LoginSection;
