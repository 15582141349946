import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material'; // Importing useMediaQuery from Material-UI
import TopNavigation from '../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/BalanceHeader.jsx'; // Adjust the path as necessary
import Deposit from './components/Deposit.jsx';
import Withdraw from './components/Withdraw.jsx';
import './PaymentPage.css'; // Assuming you'll have a CSS file for any necessary overrides

const PaymentPage = () => {
  const [activeTab, setActiveTab] = useState('deposit');
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div className="payment-page">
      {isMobile ? (
          <Header />
      ) : (
          <TopNavigation />
      )}
      
      <div className="payment-tabs-container">
        <div className="payment-tabs">
          <button 
            className={`payment-tab ${activeTab === 'deposit' ? 'active' : ''}`} 
            onClick={() => setActiveTab('deposit')}
          >
            DEPOSIT
          </button>
          <button 
            className={`payment-tab ${activeTab === 'withdraw' ? 'active' : ''}`} 
            onClick={() => setActiveTab('withdraw')}
          >
            WITHDRAW
          </button>
        </div>
      </div>

      <div className="payment-method-page">
        {activeTab === 'deposit' && <Deposit />}
        {activeTab === 'withdraw' && <Withdraw />}
      </div>
      {isMobile ? (
          <BottomNavigation />
      ) : (
          <Footer/>
      )}
    </div>
  );
};

export default PaymentPage;
