import React from "react";
import "../Profile.css";
import GradientButton from "../../../../components/Button/GradientButton";
import warningIcon from "../../../../assets/PlayerAccount/warning.svg";

const PasswordTab = () => {
  return (
    <div className="profile-method-container">
      <div className="warning-icon-container">
        <img src={warningIcon} alt="Warning" className="warn-icon" />
      </div>
      <span className="pass-note">
        Please do not share your password to keep your account safe.
      </span>
      <div className="password-container">
        <div className="pass-details">
          <div className="input-group">
            <label htmlFor="current-pass">Current Password</label>
            <input type="text" id="current-pass" placeholder="snw2usw" />
          </div>
          <div className="input-group">
            <label htmlFor="new-pass">New Password</label>
            <input type="text" id="new-pass" placeholder="w1njdmnd" />
          </div>
          <div className="input-group">
            <label htmlFor="pass-validation">Confim New Password</label>
            <input type="text" id="pass-validation" placeholder="w1njdmnd" />
          </div>
        </div>
      </div>
      <GradientButton
        style={{ marginTop: "5px", marginBottom: "16px" }}
        text="UPDATE"
        onClick={() => {}}
      />
    </div>
  );
};

export default PasswordTab;
