export const raceData = {
    1: {
      horses: [
        { id: 1, name: "PANDAY PIRA", jockey: "IL AGUILA", trainer: "Tansengco Ma A/Aguila P L", position: "4th" },
        { id: 2, name: "TURBO", jockey: "JONES SMITH", trainer: "Simpson S", position: "2nd" },
        { id: 3, name: "RAPID", jockey: "JOHN DOE", trainer: "Kramer K", position: "3rd" },
        { id: 4, name: "THUNDER", jockey: "ALEX GRACE", trainer: "Hunter J", position: "1st" },
        { id: 5, name: "BLAZE", jockey: "STEVE GATES", trainer: "Miller S", position: "scratched" },
        { id: 6, name: "LIGHTNING", jockey: "HAROLD STONE", trainer: "Davis K", position: "6th" },
      ],
      winner: "1st - #4",
      second: "2nd - #2",
      status: "completed",
    },
    2: {
      horses: [
        { id: 1, name: "SPEEDY", jockey: "BRYAN ADAMS", trainer: "Lucas R", position: "2nd" },
        { id: 2, name: "LIGHTNING", jockey: "JAMES HUNT", trainer: "Quentin P", position: "3rd" },
        { id: 3, name: "BOLT", jockey: "MARK JONES", trainer: "Richards S", position: "1st" },
        { id: 4, name: "BREEZE", jockey: "PETER WILLIAMS", trainer: "Griffin G", position: "4th" },
        { id: 5, name: "WINDRUSH", jockey: "ALAN WHITE", trainer: "Stone C", position: "scratched" },
        { id: 6, name: "STORMY", jockey: "JASON BLUE", trainer: "Kensington F", position: "6th" },
      ],
      winner: "1st - #3",
      second: "2nd - #1",
      status: "completed",
    },
    3: {
      horses: [
        { id: 1, name: "ROCKET", jockey: "SIMON HALL", trainer: "Nichols N", position: "3rd" },
        { id: 2, name: "JET", jockey: "RICHARD KING", trainer: "Gallagher M", position: "1st" },
        { id: 3, name: "FLASH", jockey: "ANTHONY PARKER", trainer: "Parker P", position: "2nd" },
        { id: 4, name: "METEOR", jockey: "LUKE WILLIAMS", trainer: "Hunter H", position: "4th" },
        { id: 5, name: "RAPIDFIRE", jockey: "ROBERT GRAY", trainer: "Turner E", position: "scratched" },
        { id: 6, name: "THUNDERCLAP", jockey: "KEVIN STONE", trainer: "Wallace P", position: "6th" },
      ],
      winner: "1st - #2",
      second: "2nd - #3",
      status: "completed",
      pick6: true,
    },
    4: {
      horses: [
        { id: 1, name: "STORM", jockey: "MICHAEL WATSON", trainer: "Daniels D", position: "1st" },
        { id: 2, name: "BLIZZARD", jockey: "ALEX JONES", trainer: "Smith J", position: "2nd" },
        { id: 3, name: "CYCLONE", jockey: "DANIEL BROWN", trainer: "Davis D", position: "3rd" },
        { id: 4, name: "TORNADO", jockey: "JAMES SCOTT", trainer: "Moore M", position: "4th" },
        { id: 5, name: "HURRICANE", jockey: "JASON CLARK", trainer: "Smith W", position: "scratched" },
        { id: 6, name: "MONSOON", jockey: "MARTIN GREEN", trainer: "Lewis B", position: "6th" },
      ],
      winner: "1st - #1",
      second: "2nd - #2",
      status: "completed",
    },
    5: {
      horses: [
        { id: 1, name: "FURY", jockey: "BRIAN GREEN", trainer: "Stevens S", position: "scratched" },
        { id: 2, name: "BLITZ", jockey: "PETER MOORE", trainer: "Johnson J", position: null },
        { id: 3, name: "MAVERICK", jockey: "ALAN WALKER", trainer: "Wilson W", position: null },
        { id: 4, name: "STINGER", jockey: "DAVID MILLER", trainer: "Williams W", position: null },
      ],
      winner: null,
      status: "open-bet",
    },
    6: {
      horses: [
        { id: 1, name: "KAJLE", jockey: "BRIAN GREEN", trainer: "Stevens S", position: "scratched" },
        { id: 2, name: "MAOR", jockey: "PETER MOORE", trainer: "Johnson J", position: null },
        { id: 3, name: "MCL", jockey: "ALAN WALKER", trainer: "Wilson W", position: null },
        { id: 4, name: "EISK", jockey: "DAVID MILLER", trainer: "Williams W", position: null },
      ],
      winner: null,
      status: "open-bet",
    },
    7: {
      horses: [
        { id: 1, name: "THUNDERBOLT", jockey: "ADAM WHITE", trainer: "Coleman C", position: "scratched" },
        { id: 2, name: "STORMCHASER", jockey: "BRIAN TAYLOR", trainer: "Edwards E", position: null },
        { id: 3, name: "WINDRIDER", jockey: "CHARLES SMITH", trainer: "Griffin G", position: null },
        { id: 4, name: "LIGHTNINGSTRIKE", jockey: "DANIEL BROWN", trainer: "Jackson J", position: null },
      ],
      winner: null,
      status: "open-bet",
    },
  };
  

// src/data/raceData.js
export const raceTypeActiveData = {
  event_name: new Date(),                  // Today's date
  arena_name: "SAN LAZARO LEISURE PARK - CARMONA ETIVAC", // Arena name
  expected_race: 7,                        // Expected race value
  bet_types: ['FC', 'DD', 'PICK5', 'PICK6', 'WTA', 'XD', 'DD+1'], // List of bet types
};
