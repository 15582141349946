import React from 'react';
import './Videomodal.css';
import closeButton from '../../../assets/Tutorial/x-button.svg';

const VideoModal = ({ isOpen, onClose, videoLink }) => {
  if (!isOpen) return null; // Don't render anything if modal is not open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={closeButton} alt="Close" className="close-button" onClick={onClose} />
        <div className="video-container">
          {/* Embed YouTube video */}
          <iframe 
            width="100%" 
            height="400px" 
            src={videoLink} 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
