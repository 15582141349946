import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import TopNavigation from '../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './Tutorial.css';
import VideoModal from './components/VideoModal'; // Import the modal component
import backIcon from "../../assets/PlayerAccount/back-icon.svg";
import regThumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import depThumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import withThumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import fcThumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import ddThumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import pk5Thumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import pk6Thumbnail from '../../assets/Tutorial/sample-thumbnail.png';
import wtaThumbnail from '../../assets/Tutorial/sample-thumbnail.png';
// Import icons for the new components
import bettingIcon from '../../assets/Tutorial/betting-rules-icon.svg';
import telegramIcon from '../../assets/Tutorial/telegram-icon.svg';
import arrowIcon from '../../assets/Tutorial/arrow-icon.svg'; // For the right-facing arrow
import { useState } from 'react';

// Updated video list with YouTube links
const videoList = [
  {
    category: "Onboarding Videos",
    videos: [
      { title: "Registration", description: "Join the Game! Easy Step-by-Step Registration!", id: "reg", thumbnail: regThumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
      { title: "Deposit", description: "Quick & Secure! How to Deposit in 3 Easy Steps!", id: "dep", thumbnail: depThumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
      { title: "Withdraw", description: "Get Your Winnings Fast! Simple Withdrawal Guide!", id: "with", thumbnail: withThumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
    ]
  },
  {
    category: "Need to learn how to bet?",
    videos: [
      { title: "Forecast (FC)", id: "fc", thumbnail: fcThumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
      { title: "Daily Double (DD)", id: "dd", thumbnail: ddThumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
      { title: "Pick 5 (PK5)", id: "pk5", thumbnail: pk5Thumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
      { title: "Pick 6 (PK6)", id: "pk6", thumbnail: pk6Thumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
      { title: "Winner Take All (WTA)", id: "wta", thumbnail: wtaThumbnail, videoLink: "https://www.youtube.com/watch?v=jPFeAAuKFa0" },
    ]
  }
];

const VideoCard = ({ title, description, thumbnail, videoLink, onPlay }) => (
  <div className="video-card" onClick={() => onPlay(videoLink)}>
    <div className="video-thumbnail">
      <img src={thumbnail} alt={`${title} Thumbnail`} className="video-cover" />
      <div className="video-play-icon">▶</div>
    </div>
    <div className="video-info">
      <h3>{title}</h3>
      {description && <p>{description}</p>}
    </div>
  </div>
);

const HowToBetPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentVideoLink, setCurrentVideoLink] = useState(null);
  const navigate = useNavigate(); // Initialize navigate hook

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page in history
  };

  const goToBetRules = () => {
    console.log("Betting Rules clicked"); // Debugging log
    navigate("/account/betting-rules"); // Define your route for betting rules
  };

  const isMobile = useMediaQuery('(max-width:768px)');

  const handlePlayVideo = (videoLink) => {
    setCurrentVideoLink(videoLink);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentVideoLink(null);
  };

  const goToTelegramSupport = () => {
    const telegramUrl = "https://web.telegram.org/k/#@pcklej"; // Replace with the actual Telegram support URL
    window.open(telegramUrl, '_blank'); // Opens the URL in a new tab
  };

  return (
    <div className='how-to-bet-container'>
      {isMobile ? 
        (<header className="page-header">
          <img src={backIcon} alt="Back" className="button" onClick={handleBackClick} />
          <h4>How To Bet</h4>
        </header>) : 
        (<>
          <TopNavigation />
          <div className="guide-header">
            <h1 className="guide-heading">Betting Guide</h1>
            <h2 className="guide-sub-heading">How to Use Karera Kings to Place Your Bets</h2>
          </div>
        </>)
      }
      <div className="how-to-bet-page">
        {videoList.map((section, idx) => (
          <div
            key={idx}
            className={`video-section ${section.category === "Onboarding Videos" ? 'onboarding-section' : 'learn-bet-section'}`}
          >
            <h2>{section.category}</h2>
            {section.videos.map((video, index) => (
              <VideoCard
                key={index}
                title={video.title}
                description={video.description}
                thumbnail={video.thumbnail}
                videoLink={video.videoLink} // Pass videoLink
                onPlay={handlePlayVideo}
              />
            ))}
          </div>
        ))}

        {/* Betting Rules Section */}
        <div className='support-nav'>
          <h4 className='bet-rules'>You can also read the bet rules here.</h4>
          <div className="info-section">
            <div className="info-item" onClick={goToBetRules} style={{ cursor: 'pointer' }}>
              <img src={bettingIcon} alt="Betting Rules" />
              <p>Betting Rules</p>
              <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
            </div>
          </div>

          <h4 className='tg-support'>Telegram additional support here.</h4>
          <div className="info-section">
            <div className="info-item" onClick={goToTelegramSupport} style={{ cursor: 'pointer' }}>
              <img src={telegramIcon} alt="Telegram" />
              <p>Telegram Support Team</p>
              <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
            </div>
          </div>
        </div>

        {/* Render Video Modal */}
        <VideoModal isOpen={isModalOpen} onClose={handleCloseModal} videoLink={currentVideoLink} />
      </div>
      {isMobile ? (<BottomNavigation />) : (<Footer/>)}
    </div>
  );
};

export default HowToBetPage;