import React, { useEffect } from "react";
import "./CopyPopup.css"; // Import the CSS for styling


const CopyPopup = ({ show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 900); // Hide after 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="copy-popup">
      <div className="copy-popup-content">
        <span>Copied</span>
      </div>
    </div>
  );
};

export default CopyPopup;