import React from "react";

// Reusable GradientButton component
const GradientButton = ({
  text = "Button",
  textColor = "#000",
  onClick,
  style = {},
}) => {
  const buttonStyles = {
    background: "linear-gradient(90deg, #FFF61F 27%, #7BF342 100%)",
    border: "none",
    borderRadius: "25px",
    color: textColor,
    fontSize: "17px",
    fontWeight: "bold",
    padding: "15px 30px",
    cursor: "pointer",
    display: "inline-block",
    textAlign: "center",
    width: "100%",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    ...style,
  };

  return (
    <button style={buttonStyles} onClick={onClick}>
      {text}
    </button>
  );
};

export default GradientButton;
