import React from 'react';
import './ErrorBoundary.css';
import Header from '../../../components/Header/BalanceHeader';
import Footer from '../../../components/Footer/Footer.jsx';
import errorImage from '../../../assets/Maintenance/error-boundary.svg'; // Make sure to correct the path based on your setup

const ErrorBoundary = () => {
    return (
        <>
            <Header />
            <div className="error-boundary-page">
                <img src={errorImage} alt="Oops!" className="error-image" />
                <h1>Hold Your Horses!</h1>
                <p className="error-message">Looks like we’ve hit a bump in the track.</p>
                <p className="return-message">We’ll get you back to the action in no time!</p>
            </div>
            <Footer/>
        </>
    );
};

export default ErrorBoundary;
