import React from "react";
import "./BackHeader.css";
import HeaderChat from "../../assets/Header/HeaderChat.png";
import HeaderHowtoBet from "../../assets/Header/HeaderHowtoBet.png";
import backIcon from "../../assets/PlayerAccount/back-icon.svg";

const Header = ({ displayText, onBackClick }) => {
  return (
    <header className="header-container">
      <div className="text-section">
        {/* Ensure the onBackClick is properly attached to the img element */}
        <img src={backIcon} alt="Back" className="back-icon" onClick={onBackClick} />
        <span>{displayText}</span>
      </div>
      <div className="action-buttons-section">
        <a href="https://web.telegram.org/k/#@pcklej" target="_blank" rel="noopener noreferrer">
          <img src={HeaderChat} alt="Chat" className="b-chat-btn" />
        </a>
        <a href="https://your-how-to-bet-url.com" target="_blank" rel="noopener noreferrer">
          <img src={HeaderHowtoBet} alt="How to Bet" className="b-how-to-bet-btn" />
        </a>
      </div>
    </header>
  );
};

export default Header;
