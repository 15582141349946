// src/components/RaceEvent/RaceVideoSection.jsx
import React from "react";

const RaceVideoSection = () => {
  return (
    <div className="race-video-section">
      <iframe
        className="race-video"
        width="100%"
        height="200"
        src="https://www.youtube.com/embed/7p5dBLJVlVU?si=yJgeMSdbVpJSIYJA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default RaceVideoSection;
