import { create } from "zustand";

const apiURL = process.env.REACT_APP_API_URL;

export const useAuthStore = create((set) => ({
  user: null,
  isAuthenticated: false,
  token: null,
  error: null,
  initialLoading: true,
  loginLoading: false,
  registerLoading: false,

  register: async (
    firstName,
    lastName,
    mobileNumber,
    password,
    referralCode
  ) => {
    set({ registerLoading: true });
    try {
      const response = await fetch(`${apiURL}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          mobileNumber,
          password,
          referralCode,
        }),
      });

      if (response.status === 201) {
        const data = await response.json();
        set({
          user: { mobileNumber, firstName, lastName },
          isAuthenticated: true,
          error: null,
        });
        localStorage.setItem("token", data.token); // Save token if received
      } else {
        const errorData = await response.json();
        let errorMessage = "Error during registration!";

        if (response.status === 422) {
          errorMessage = errorData.message;
        } else if (response.status === 400) {
          errorMessage = errorData.message;
        }

        set({
          user: null,
          isAuthenticated: false,
          error: errorMessage,
        });
      }
    } catch (error) {
      set({
        user: null,
        isAuthenticated: false,
        error: error.message,
      });
    } finally {
      set({ registerLoading: false });
    }
  },

  // Load auth state from localStorage on initialization
  loadAuthState: () => {
    const token = localStorage.getItem("token");
    if (token) {
      set({ isAuthenticated: true, token, initialLoading: false });
    } else {
      set({ initialLoading: false });
    }
  },

  // Login function to authenticate user and save token in localStorage
  login: async (mobileNumber, password) => {
    set({ loginLoading: true });
    try {
      const response = await fetch(`${apiURL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobileNumber, password }),
      });

      if (response.status === 201) {
        const token = await response.text();
        set({
          user: { mobileNumber },
          token,
          isAuthenticated: true, // Set isAuthenticated to true
          error: null,
        });
        localStorage.setItem("token", token); // Save token in localStorage
      } else {
        const errorData = await response.json();
        let errorMessage = "An error occurred";

        if (response.status === 404) {
          errorMessage = errorData.message;
        } else if (response.status === 403) {
          errorMessage = errorData.message;
        }

        set({
          user: null,
          token: null,
          isAuthenticated: false, // Set isAuthenticated to false
          error: errorMessage,
        });
      }
    } catch (error) {
      set({
        user: null,
        token: null,
        isAuthenticated: false,
        error: error.message,
      });
    } finally {
      set({ loginLoading: false });
    }
  },

  logout: () => {
    set({ user: null, token: null, isAuthenticated: false });
    localStorage.removeItem("token"); // Remove token from localStorage
  },
}));
