import React from 'react';
import './RaceClosedBetting.css'; // Link to the CSS file

const EventClosedBetting = () => {
  return (
    <div className="race-closed-betting">
    </div>
  );
};

export default EventClosedBetting;
