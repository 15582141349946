import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import TopNavigation from "../../../components/TopNavigation/TopNavigation.jsx";
import BottomNavigation from "../../../components/BottomNavigation/BottomNavigation.jsx";
import Footer from "../../../components/Footer/Footer.jsx";
import "./About.css";
import Header from "../../../components/Header/BackHeader.jsx";
import backgroundImage from "../../../assets/PlayerAccount/about-header.svg";
import karreraKingImage from "../../../assets/PlayerAccount/about-header-king.svg";
import hariNgKareraImage from "../../../assets/PlayerAccount/about-header-capt.svg";
import secureIcon from "../../../assets/PlayerAccount/secure-betting-icon.svg";
import cashOutIcon from "../../../assets/PlayerAccount/cashout-icon.svg";
import racingIcon from "../../../assets/PlayerAccount/racing-icon.svg";

const AboutPage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const navigate = useNavigate(); // Initialize navigate hook

  const handleBackClick = () => {
    console.log("Back icon clicked"); // Debugging log
    navigate("/account"); // Navigate to the previous page in history
  };

  return (
    <div className="about-page-container">
      {isMobile ? (
        <>
          <Header displayText="About" onBackClick={handleBackClick} />
          <h4 className="label">About</h4>
        </>
      ) : (
        <>
          <TopNavigation />
          <div className="desktop-header">
            <h1 className="heading">ABOUT KARERA KINGS</h1>
            <h2 className="sub-heading">THE THRILL OF HORSE RACING</h2>
          </div>
        </>
      )}
      {/* Background image container */}
      <div className="about-details-container">
        <div className="about-background-container">
          <img
            src={backgroundImage}
            alt="Background"
            className="about-background"
          />

          <img
            src={karreraKingImage}
            alt="Karrera King"
            className="karrera-king-logo"
          />
          <img
            src={hariNgKareraImage}
            alt="Hari ng Karera"
            className="hari-ng-karera-logo"
          />
        </div>
        <div className="about-content">
          <p>
            <strong>Karera King:</strong> The Premier Horse Racing Betting
            Platform in the Philippines
          </p>
          <p>
            Sa Karera King, ipinagmamalaki naming maging bahagi ng paboritong
            tradisyon ng mga Pilipino— ang karera ng kabayo. Kami ay isang
            makabayan at maaasahang plataporma para sa horse racing betting, na
            naglalayong magdala ng sigla at saya sa bawat Pilipinong mananaya.
          </p>
          <p>
            We believe in the thrill of horse racing, a sport deeply rooted in
            Filipino culture. Karera King is committed to providing a secure,
            fair, and exciting betting experience, ensuring that every race you
            bet on is filled with excitement and pride.
          </p>
          <p>
            Pinapahalagahan namin ang pagtutulungan at tiwala, tulad ng
            pagkakaisa ng mga kababayan natin sa bawat tagumpay.
          </p>
          <p>
            Whether you’re a seasoned bettor or new to the sport, Karera King is
            here to bring you closer to the action. Experience the passion, the
            excitement, and the joy of winning with us. Tayo’y magkaisa sa
            ilalim ng isang watawat, at magdiwang ng tagumpay sa bawat karera.
          </p>
          <p>Race Supreme with Karera King!</p>
          {/* Icons container */}
          <div className="about-icons-container">
            <div className="icon">
              <img src={secureIcon} alt="Secure Betting" />
            </div>
            <div className="icon">
              <img src={cashOutIcon} alt="Quick CashOut" />
            </div>
            <div className="icon">
              <img src={racingIcon} alt="Racing" />
            </div>
          </div>
        </div>
      </div>
      {isMobile ? <BottomNavigation /> : <Footer />}
    </div>
  );
};

export default AboutPage;
