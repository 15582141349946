import React from 'react';
import './Maintenance.css';
import Header from '../../../components/Header/BalanceHeader';
import Footer from '../../../components/Footer/Footer.jsx';
import maintenanceImage from '../../../assets/Maintenance/Maintenance.svg'; // Make sure to correct the path based on your setup

const Maintenance = () => {
    return (
        <>
            <Header />
            <div className="maintenance-page">
                <img src={maintenanceImage} alt="We'll be back soon" className="maintenance-image" />
                <h1>Hold Your Horses!</h1>
                <p className="maintenance-message">We're just making a quick maintenance.</p>
                <p className="return-message">Our site will be back on track shortly, ready for more winning bets!</p>
            </div>
            <Footer/>
        </>
    );
};

export default Maintenance;
