import React, { useState } from 'react';
import './PrivatePolicy.css';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import TopNavigation from '../../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import Header from '../../../components/Header/BackHeader.jsx';

const PrivatePolicy = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const [language, setLanguage] = useState('tagalog'); // Set Tagalog as default
    const [expandedSections, setExpandedSections] = useState({});

    const handleBackClick = () => {
        console.log('Back icon clicked');
        navigate('/account');
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const content = {
        english: {
            effectiveDate: "Effective Date: October 7, 2024",
            sections: [
                {
                    title: "Introduction",
                    content: (
                        <>
                            <p>At Karera King, we are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, store, and protect your personal information when you use our online horse race betting system. By using our platform, you agree to the terms described in this policy.</p>
                        </>
                    )
                },
                {
                    title: "Personal Data We Collect",
                    content: (
                        <>
                            <p>We collect and process various types of personal data, including but not limited to:</p>
                            <ul>
                                <li>Full Name</li>
                                <li>Phone Number</li>
                                <li>Payment Details</li>
                                <li>Proof of Transaction</li>
                                <li>Account Number</li>
                            </ul>
                            <p>We collect this data through account registration, transactions, and your use of the platform.</p>
                        </>
                    )
                },
                {
                    title: "Cookie Policy",
                    content: (
                        <>
                            <p>Our website uses cookies to enhance your betting experience. Cookies are small files stored on your device that help us recognize you and remember your preferences.</p>
                            <p>We use the following types of cookies:</p>
                            <ul>
                                <li><strong>Essential Cookies:</strong> Required for the basic functionality of our site, such as security and navigation.</li>
                                <li><strong>Analytical Cookies:</strong> To analyze usage patterns, allowing us to improve the platform.</li>
                                <li><strong>Preference Cookies:</strong> To remember your preferences, such as language or bet types.</li>
                                <li><strong>Marketing Cookies:</strong> Used to show personalized ads relevant to your interests.</li>
                            </ul>
                            <p>You may disable cookies in your browser settings; however, this may impact your experience on our site.</p>
                        </>
                    )
                },
                {
                    title: "Retention and Deletion",
                    content: (
                        <>
                            <p>We retain your personal data only for as long as it is necessary to provide services, fulfill legal requirements, and maintain accurate financial records.</p>
                            <ul>
                                <li><strong>Personal Data:</strong> Stored for the duration of your active account and for a period after account closure as required by law.</li>
                                <li><strong>Betting Data:</strong> Retained for record-keeping purposes and to comply with legal or regulatory requirements.</li>
                            </ul>
                            <p>Once the retention period expires, your personal data will be deleted or anonymized unless retention is required.</p>
                        </>
                    )
                },
                {
                    title: "Your Rights to Your Personal Data",
                    content: (
                        <>
                            <p>As a user of Karera King, you have the following rights regarding your personal data:</p>
                            <ul>
                                <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
                                <li><strong>Correction: </strong>You have the right to request corrections to any inaccurate or incomplete personal data.</li>
                                <li><strong>Deletion:</strong> You may request the deletion of your personal data where it is no longer necessary for the purpose it was collected, subject to legal obligations.</li>
                                <li><strong>Restriction:</strong> You can request a restriction on the processing of your personal data under certain circumstances.</li>
                                <li><strong>Objection:</strong> You have the right to object to the processing of your personal data for marketing purposes.</li>
                                <li><strong>Data Portability:</strong> You can request a copy of your data in a structured, commonly used format.</li>
                            </ul>
                            <p>To exercise your rights, contact us using the details in the Contact Us section.</p>
                        </>
                    )
                },
                {
                    title: "Changes",
                    content: (
                        <>
                            <p>We may update this Privacy Policy from time to time to reflect changes in legal requirements, data processing practices, or our services. When changes are made, we will update the "Effective Date" and notify you either via email or by prominently posting the updated policy on our platform.</p>
                            <p>Your continued use of Karera King after any changes will signify your acceptance of the updated policy.</p>
                        </>
                    )
                },
                {
                    title: "Complaints",
                    content: (
                        <>
                            <p>If you believe that your personal data has been handled improperly or that your rights under data protection laws have been violated, you have the right to lodge a complaint.</p>
                            <p>You can file a complaint directly with:</p>
                            <ul>
                                <li>Karera King’s Data Protection Officer (DPO) via the contact information below, or</li>
                                <li>Your local data protection authority if applicable.</li>
                            </ul>
                            <p>We aim to resolve any concerns promptly and transparently.</p>
                        </>
                    )
                },
                {
                    title: "Contact Us",
                    content: (
                        <>
                            <p>If you have any questions, concerns, or requests regarding your personal data or this Privacy Policy, please contact us:</p>
                            <ul>
                                <li>Email: privacy@kareraking.com</li>
                                <li>Phone: +63 123 4567 890</li>
                                <li>Telegram: <a href="https://web.telegram.org/k/#@pcklej" target="_blank" rel="noopener noreferrer">Karera King Support</a></li>
                            </ul>
                            <p>We are committed to ensuring your privacy and providing you with a secure and enjoyable betting experience.</p>
                        </>
                    )
                }
            ]
        },
        tagalog: {
            effectiveDate: "Petsa ng Pagkakabisa: Oktubre 7, 2024",
            sections: [
                {
                    title: "Panimula",
                    content: (
                        <>
                            <p>Sa Karera King, kami ay nakatuon sa pagprotekta sa privacy ng aming mga gumagamit. Ang Patakaran sa Privacy na ito ay nagpapaliwanag kung paano namin kinokolekta, ginagamit, iniimbak, at pinoprotektahan ang iyong personal na impormasyon kapag ginagamit mo ang aming online horse race betting system. Sa pamamagitan ng paggamit ng aming platform, sumasang-ayon ka sa mga tuntuning nakasaad sa patakarang ito.</p>
                        </>
                    )
                },
                {
                    title: "Mga Personal na Datos na Kinokolekta Namin",
                    content: (
                        <>
                            <p>Kinokolekta at pinoproseso namin ang iba’t ibang uri ng personal na data, kabilang ang, ngunit hindi limitado sa:</p>
                            <ul>
                                <li> Buong Pangalan</li>
                                <li>Numero ng Telepono</li>
                                <li>Detalye sa Pagbabayad</li>
                                <li>Ebidensya ng Transaksyon</li>
                                <li>Account Number</li>
                            </ul>
                            <p>Kinokolekta namin ang data na ito sa pamamagitan ng pagrehistro ng account, mga transaksyon, at paggamit mo ng platform.</p>
                        </>
                    )
                },
                {
                    title: "Patakaran sa Cookies",
                    content: (
                        <>
                            <p>Ang aming website ay gumagamit ng cookies upang mapabuti ang iyong karanasan sa pagtaya. Ang cookies ay maliliit na file na nakaimbak sa iyong device na tumutulong sa amin na makilala ka at tandaan ang iyong mga kagustuhan.</p>
                            <p>Ginagamit namin ang sumusunod na uri ng cookies:</p>
                            <ul>
                                <li>Essential Cookies: Kinakailangan para sa pangunahing pag-andar ng aming site, tulad ng seguridad at nabigasyon.</li>
                                <li>Analytical Cookies: Upang pag-aralan ang mga pattern ng paggamit, na nagbibigay-daan sa amin na mapabuti ang platform.</li>
                                <li>Preference Cookies: Upang tandaan ang iyong mga kagustuhan, tulad ng wika o uri ng taya.</li>
                                <li>Marketing Cookies: Ginagamit upang magpakita ng mga personalized na ads na naaayon sa iyong mga interes.</li>
                            </ul>
                            <p>Maaari mong i-disable ang cookies sa mga setting ng iyong browser; gayunpaman, maaaring maapektuhan nito ang iyong karanasan sa aming site.</p>
                        </>
                    )
                },
                {
                    title: "Pag-Kolekta at Pagbura",
                    content: (
                        <>
                            <p>Itatago lamang namin ang iyong personal na datos hangga’t kinakailangan upang makapagbigay ng mga serbisyo, matugunan ang mga legal na kinakailangan, at mapanatili ang mga tamang talaan ng pananalapi.</p>
                            <ul>
                                <li>Personal na Data: Iniimbak habang aktibo ang iyong account at sa loob ng isang panahon matapos ang pagsasara ng account bilang itinakda ng batas.</li>
                                <li>Datos sa Pagtaya: Pinanatili para sa mga layuning pangtala at pagsunod sa mga kinakailangang legal o regulasyon.</li>
                            </ul>
                            <p>Kapag natapos na ang panahon ng pag-iimbak, ang iyong personal na data ay buburahin o gagawing anonymous maliban kung kinakailangan ng batas na itago ito.</p>
                        </>
                    )
                },
                {
                    title: "Ang Iyong Mga Karapatan Tungkol sa Iyong Personal na Data",
                    content: (
                        <>
                            <p>Bilang isang gumagamit ng Karera King, mayroon kang mga sumusunod na karapatan patungkol sa iyong personal na data:</p>
                            <ul>
                                <li>Pag-access: Maaari kang humiling ng kopya ng personal na data na hawak namin tungkol sa iyo.</li>
                                <li>Pagwawasto: May karapatan kang humiling ng mga pagbabago sa anumang hindi tama o kulang na personal na data.</li>
                                <li>Pagbura: Maaari kang humiling ng pagbura ng iyong personal na data kung hindi na kinakailangan para sa layunin na kinolekta ito, maliban kung may legal na obligasyon.</li>
                                <li>Paghihigpit: Maaari kang humiling ng paghihigpit sa pagproseso ng iyong personal na data sa ilalim ng ilang mga pangyayari.</li>
                                <li>Pagtutol: May karapatan kang tutulan ang pagproseso ng iyong personal na data para sa mga layunin ng marketing.</li>
                                <li>Data Portability: Maaari kang humiling ng kopya ng iyong data sa isang nakaayos na, karaniwang ginagamit na format.</li>
                            </ul>
                            <p>Upang gamitin ang iyong mga karapatan, makipag-ugnayan sa amin gamit ang mga detalye sa seksyong Makipag-ugnayan sa Amin.</p>
                        </>
                    )
                },
                {
                    title: "Mga Pagbabago",
                    content: (
                        <>
                            <p>Maaaring i-update namin ang Patakaran sa Privacy na ito paminsan-minsan upang ipakita ang mga pagbabago sa mga legal na kinakailangan, mga kasanayan sa pagproseso ng data, o aming mga serbisyo. Kapag may mga pagbabago, ia-update namin ang "Petsa ng Pagkakabisa" at ipapaalam ito sa iyo sa pamamagitan ng email o sa pamamagitan ng pag-post ng na-update na patakaran sa aming platform.</p>
                            <p>Ang iyong patuloy na paggamit ng Karera King pagkatapos ng mga pagbabago ay mangangahulugan ng iyong pagtanggap sa na-update na patakaran.</p>
                        </>
                    )
                },
                {
                    title: "Mga Reklamo",
                    content: (
                        <>
                            <p>Kung naniniwala kang hindi wastong hinawakan ang iyong personal na data o nilabag ang iyong mga karapatan sa ilalim ng mga batas sa proteksyon ng data, may karapatan kang maghain ng reklamo.</p>
                            <p>Maaari kang magsampa ng reklamo direkta sa:</p>
                            <ul>
                                <li>Data Protection Officer (DPO) ng Karera King sa pamamagitan ng mga contact details sa ibaba, o</li>
                                <li>Sa iyong lokal na awtoridad sa proteksyon ng data, kung naaangkop.</li>
                            </ul>
                            <p>Layunin naming lutasin ang anumang alalahanin nang mabilis at malinaw.</p>
                        </>
                    )
                },
                {
                    title: "Makipag-ugnayan sa Amin",
                    content: (
                        <>
                            <p>Kung mayroon kang anumang mga katanungan, alalahanin, o kahilingan tungkol sa iyong personal na data o sa Patakaran sa Privacy na ito, mangyaring makipag-ugnayan sa amin:</p>
                            <ul>
                                <li>Email: privacy@kareraking.com</li>
                                <li>Telepono: +63 123 4567 890</li>
                                <li>Telegram: <a href="https://web.telegram.org/k/#@pcklej" target="_blank" rel="noopener noreferrer">Karera King Support</a></li>
                            </ul>
                            <p>Nais naming tiyakin ang iyong privacy at magbigay sa iyo ng isang ligtas at kasiya-siyang karanasan sa pagtaya.</p>
                        </>
                    )
                }
            ]
        }
    };

    return (
        <>
            {isMobile ? (<Header displayText='Private Policy' onBackClick={handleBackClick}/>) : (<TopNavigation />)}
            <div className="private-policy-page">
                <div className="private-policy-container">
                    <div className="language-selector">
                        <label className='language-label'>
                            Language:
                        </label>
                        <select value={language} onChange={handleLanguageChange}>
                            <option value="english">English</option>
                            <option value="tagalog">Tagalog</option>
                        </select>
                    </div>
                    <h4 className="private-policy-label">Private Policy</h4>
                    <div className="private-policy-detail-container">
                        <p className='effective-date'>{content[language].effectiveDate}</p>
                        {content[language].sections.map((section, index) => (
                            <div key={index} className="private-policy-section">
                                <div className="section-header" onClick={() => toggleSection(index)}>
                                    <h5 className='section-title'>{section.title}</h5>
                                    <button>{expandedSections[index] ? '-' : '+'}</button>
                                </div>
                                {expandedSections[index] && <div className="private-policy-content">{section.content}</div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {isMobile ? (<BottomNavigation />) : (<Footer/>)}
        </>
    );
};

export default PrivatePolicy;