import React from "react";
import "../Transaction.css";
import { CurrencyFormatter } from "../../../formatter/currencyFormatter";

const Bets = () => {
  return (
    <div className="bet-container">
      <table className="bet-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Bets</th>
            <th>Bet Amount</th>
            <th>Bet Result</th>
          </tr>
        </thead>
        <tbody>
          {/* Double Example */}
          <tr>
            <td>Sept 11 19:10</td>
            <td>
              <strong>Double</strong>
              <br />
              <strong>R1 - R2</strong>
              <br />
              <br />
              Ticket No. 726272
              <br />
              <strong>R1:</strong>
              <div className="bet-line">
                <div className="bet-circle">1</div>
              </div>
              <strong>R2:</strong>
              <div className="bet-line">
                <div className="bet-circle">3</div>
              </div>
            </td>

            <td>
              <strong>10 x 2</strong>
              <strong>= 20</strong>
            </td>
            <td>
              WIN
              <br />
              <span className="bet-result-amount">
                Amount: <CurrencyFormatter value={100} />
              </span>
            </td>
          </tr>

          {/* Forecast Example */}
          <tr>
            <td>Sept 11 08:10</td>
            <td>
              <strong>Forecast</strong>
              <br />
              <strong>R3</strong>
              <br />
              <br />
              Ticket No. 3638
              <br />
              <strong>1st:</strong>
              <div className="bet-line">
                <div className="bet-circle">1</div>
              </div>
              <strong>2nd:</strong>
              <div className="bet-line">
                <div className="bet-circle">2</div>
              </div>
            </td>
            <td>
              <strong>5 x 3</strong>
              <strong>= 15</strong>
            </td>
            <td>
              Lose
              <br />
              <span className="bet-result-amount">
                Amount: <CurrencyFormatter value={-15} />
              </span>
            </td>
          </tr>

          {/* WTA Example with Multiple Numbers */}
          <tr>
            <td>Sept 12 11:30</td>
            <td>
              <strong>WTA</strong>
              <br />
              <strong>R1-R7</strong>
              <br />
              <br />
              Ticket No. 12726
              <br />
              <strong>R1:</strong>
              <div className="bet-line">
                <div className="bet-circle">1</div>
                <div className="bet-circle">2</div>
                <div className="bet-circle">3</div>
              </div>
              <strong>R2:</strong>
              <div className="bet-line">
                <div className="bet-circle">2</div>
                <div className="bet-circle">4</div>
              </div>
            </td>

            <td>
              <strong>1 x 11</strong>
              <strong>= 11</strong>
            </td>
            <td>
              Refund
              <br />
              <span className="bet-result-amount">
                Amount: <CurrencyFormatter value={5} />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Bets;
