import React from "react";
import PropTypes from "prop-types";

export const CurrencyFormatter = ({
  value,
  style = "currency",
  currency = "PHP",
  minimumFractionDigits = 2,
}) => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style,
    currency,
    minimumFractionDigits,
  }).format(value);

  return <span>{formattedValue}</span>;
};

CurrencyFormatter.propTypes = {
  value: PropTypes.number.isRequired,
  style: PropTypes.oneOf(["decimal", "currency", "percent"]),
  currency: PropTypes.string,
  minimumFractionDigits: PropTypes.number,
};
