import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BettingRules.css';
import Header from '../../../components/Header/BackHeader.jsx';
import { useMediaQuery } from '@mui/material';
import TopNavigation from '../../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';

const BettingRules = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const [language, setLanguage] = useState('tagalog');

    const handleBackClick = () => {
        console.log('Back icon clicked');
        navigate('/account');
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const rulesContent = {
        english: [
            "1. Account Registration: To bet, you must first sign up for Karera King.",
            "2. Deposits: Players must load funds to the designated loader listed on the deposit page before filling out the deposit form.",
            "3. Bet Submission: Once a bet is submitted, it cannot be changed or canceled.",
            "4. Cash-Out Limit: The maximum cash-out per transaction is 30,000 PHP.",
            "5. Sales Locations: All bets must be made through the Karera King's website.",
            "6. Dividends: If your ticket wins, your payout will be credited to your Karera King account as soon as the winning horses are officially announced and final dividend calculations are done.",
            "7. Ticket Validation: A computer system will verify if your ticket is valid for a payout.",
            "8. Race Changes: Any official race changes or cancellations will be announced publicly, and these changes will be final once announced.",
            "9. Scratched Horses: If a horse is scratched (withdrawn), it will be considered a non-starter, and this will be announced through the Club's public announcements.",
            "10. Coupled Entries: If a race has coupled horses (two horses racing together as one entry), they count as a single entry.",
            "11. Betting Limits: If you place multiple bets of the same type, there may be a betting limit on how much you can wager for that bet type.",
            "12. Betting Deadline: Players cannot place any bets after the betting period has closed."
        ],
        tagalog: [
            "1. Pagpaparehistro ng Account: Para makapagtaya, kailangan munang mag-sign up sa Karera King.",
            "2. Pag-deposito: Kailangang mag-load ng pondo ang mga manlalaro sa itinalagang loader na makikita sa deposit page bago punan ang deposit form.",
            "3. Pagsumite ng Taya: Kapag naisumite na ang taya, hindi na ito mababago o makakansela.",
            "4. Limitasyon ng Cash-Out: Ang maximum na halaga ng cash-out kada transaksyon ay 30,000 PHP.",
            "5. Mga Lokasyon ng Pagtaya: Ang lahat ng taya ay dapat gawin sa website ng Karera King.",
            "6. Mga Dibidendo: Kung mananalo ang iyong ticket, ang iyong premyo ay agad na ikikredit sa iyong Karera King account pagkatapos opisyal na ideklara ang mga nanalong kabayo at matapos ang huling kalkulasyon ng dibidendo.",
            "7. Pagpapatunay ng Ticket: Ichecheck ng computer system kung valid ang iyong ticket para makakuha ng premyo.",
            "8. Mga Pagbabago sa Karera: Anumang opisyal na pagbabago o pagkansela ng mga karera ay iaanunsyo sa publiko, at ang mga pagbabagong ito ay pinal na kapag naibigay na.",
            "9. Mga Na-scratch na Kabayo: Kung ang isang kabayo ay na-scratch (tinanggal sa karera), ito ay ituturing na hindi nakasali, at iaanunsyo ito sa pamamagitan ng opisyal na anunsyo ng Club.",
            "10. Coupled Entries: Kung ang isang karera ay may mga coupled horses (dalawang kabayo na lumalaban bilang isang entry), bibilangin ito bilang isang entry lamang.",
            "11. Limitasyon sa Pagtaya: Kung maglagay ka ng maraming taya ng parehong uri, maaaring may limitasyon sa kung magkano ang maaari mong itaya para sa uri ng pagtayang iyon.",
            "12. Pagsasara ng Pagtaya: Hindi na maaaring maglagay ng taya ang mga manlalaro kapag sarado na ang oras ng pagtaya."
        ]
    };

    const poolTypes = {
        english: [
            { type: "WIN (W)", minBet: "₱1.00", winLimit: "₱20,000.00", combination: "Predict the first place winner in a specific race." },
            { type: "FORECAST (FC)", minBet: "₱1.00", winLimit: "₱25,000.00", combination: "Predict the first and second place finishers in the exact order in a designated race." },
            { type: "DAILY DOUBLE (DD)", minBet: "₱1.00", winLimit: "₱30,000.00", combination: "Predict the first place winners in two designated consecutive races." },
            { type: "DAILY DOUBLE +1 (DD+1)", minBet: "₱1.00", winLimit: "₱30,000.00", combination: "Predict the first place winners in three designated consecutive races." },
            { type: "EXTRA DOUBLE (XD)", minBet: "₱1.00", winLimit: "₱30,000.00", combination: "Predict the first place winners in two designated alternating races." },
            { type: "PICK 5 (PICK5)", minBet: "₱2.00", winLimit: "₱25,000.00", combination: "Predict the first place winners in five designated consecutive races." },
            { type: "PICK 6 (PICK6)", minBet: "₱2.00", winLimit: "₱30,000.00", combination: "Predict the first place winners in six designated consecutive races." },
            { type: "WINNER TAKE ALL (WTA)", minBet: "₱2.00", winLimit: "₱35,000.00", combination: "Predict the first place winners in seven designated consecutive races." }
        ],
        tagalog: [
            { type: "WIN (W)", minBet: "₱1.00", winLimit: "₱20,999.00", combination: "Hulaan ang unang mananalo sa isang partikular na karera." },
            { type: "FORECAST (FC)", minBet: "₱1.00", winLimit: "₱25,999.00", combination: "Hulaan ang unang at pangalawang mananalo sa eksaktong pagkakasunod sa isang tinukoy na karera." },
            { type: "DAILY DOUBLE (DD)", minBet: "₱1.00", winLimit: "₱30,999.00", combination: "Hulaan ang mga unang mananalo sa dalawang sunod-sunod na karera." },
            { type: "DAILY DOUBLE +1 (DD+1)", minBet: "₱1.00", winLimit: "₱30,999.00", combination: "Hulaan ang mga unang mananalo sa tatlong sunod-sunod na karera." },
            { type: "EXTRA DOUBLE (XD)", minBet: "₱1.00", winLimit: "₱30,999.00", combination: "Hulaan ang mga unang mananalo sa dalawang salit-salitang karera." },
            { type: "PICK 5 (PICK5)", minBet: "₱2.00", winLimit: "₱25,999.00", combination: "Hulaan ang mga unang mananalo sa limang sunod-sunod na karera." },
            { type: "PICK 6 (PICK6)", minBet: "₱2.00", winLimit: "₱30,999.00", combination: "Hulaan ang mga unang mananalo sa anim na sunod-sunod na karera." },
            { type: "WINNER TAKE ALL (WTA)", minBet: "₱2.00", winLimit: "₱40,000.00", combination: "Hulaan ang mga unang mananalo sa pitong sunod-sunod na karera." }
        ]
    };

    return (
        <>
            {isMobile ? (<Header displayText='Betting Rules' onBackClick={handleBackClick}/>) : (<TopNavigation />)}
            <div className="betting-rules-page">
                <div className="betting-rules-container">
                    <div className="language-selector">
                        <label className='language-label'>
                            Language:
                        </label>
                            <select value={language} onChange={handleLanguageChange}>
                                <option value="tagalog">Tagalog</option>
                                <option value="english">English</option>
                            </select>
                    </div>
                    <h4 className="label">Karera King Betting Rules</h4>
                    <div className="rules-detail-container">
                        <div className="rules-details">
                            <div className="rules-content no-select">
                                {rulesContent[language].map((rule, index) => (
                                    <p key={index}><strong>{rule.split(':')[0]}:</strong>{rule.split(':')[1]}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="pool-types-container">
                        <h4 className="label">Betting Pool Types</h4>
                        <table className="pool-types-table no-select">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Minimum Bet</th>
                                    <th>Winning Limit</th>
                                    <th>Combination</th>
                                </tr>
                            </thead>
                            <tbody>
                                {poolTypes[language].map((pool, index) => (
                                    <tr key={index}>
                                        <td>{pool.type}</td>
                                        <td>{pool.minBet}</td>
                                        <td>{pool.winLimit}</td>
                                        <td>{pool.combination}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isMobile ? (<BottomNavigation />) : (<Footer/>)}
        </>
    );
};

export default BettingRules;