import React from 'react';
import '../Profile.css';

const ProfileTab = () => {
  const formData = {
    playerId: 'KK19421',
    fullName: 'Juan Dela Cruz',
    mobileNumber: 'XXXXXXXXXX',
    email: 'juan@gmail.com',
  };

  return (
        <div className="profile-form-container">
          <div className="profile-form">
            <div className="input-group">
              <label htmlFor="player-id">Player ID</label>
              <input
                type="text"
                id="player-id"
                value={formData.playerId}
                disabled // Always disabled
              />
            </div>
            <div className="input-group">
              <label htmlFor="full-name">Full Name</label>
              <input
                type="text"
                id="full-name"
                value={formData.fullName}
                disabled // Always disabled
              />
            </div>
            <div className="input-group">
              <label htmlFor="mobile-number">Mobile Number</label>
              <input
                type="text"
                id="mobile-number"
                value={formData.mobileNumber}
                disabled // Always disabled
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                value={formData.email}
                disabled // Always disabled
              />
            </div>
          </div>
        </div>
  );
};

export default ProfileTab;
