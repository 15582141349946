import { create } from "zustand";
import axios from "axios";
import { useAuthStore } from "./useAuthStore";

const apiURL = process.env.REACT_APP_API_URL;

// ... existing code ...

const useEventsStore = create((set) => ({
  events: [],
  latestEvent: null,
  activeEvent: null, // Add state for active event
  races: [],

  fetchActiveEvent: async () => {
    const { token } = useAuthStore.getState();
    try {
      const response = await axios.get(`${apiURL}/event/status/active`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const activeEvents = response.data;
        if (activeEvents.length > 0) {
          const activeEvent = activeEvents[0]; // Assuming only one active event
          set({ activeEvent, races: activeEvent.races });
        } else {
          console.error("No active event found");
        }
      } else {
        console.error("Failed to fetch active event: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching active event:", error.message);
    }
  },

}));

export default useEventsStore;
