import React, { useState } from "react";
import "../Transaction.css";

const Wallet = () => {
  const transactions = [
    {
      id: "TXN001",
      date: "2024-09-15",
      to: { gcashNumber: "09171234567", name: "John Doe" },
      from: { gcashNumber: "09179876543" },
      refNumber: "REF12345",
      type: "Withdrawal",
      amount: "₱150.00",
      status: "Approved",
    },
    {
      id: "TXN002",
      date: "2024-09-14",
      to: { gcashNumber: "09281234567", name: "Jane Smith" },
      from: { gcashNumber: "09175556789" },
      refNumber: "REF12346",
      type: "Deposit",
      amount: "₱200.00",
      status: "Pending",
    },
    {
      id: "TXN003",
      date: "2024-09-13",
      to: { gcashNumber: "09391234567", name: "Alice Brown" },
      from: { gcashNumber: "09174561234" },
      refNumber: "REF12347",
      type: "Withdrawal",
      amount: "₱100.00",
      status: "Declined",
    },
    // Add more transaction objects as needed
  ];

  const [filterType, setFilterType] = useState("All");
  const [filterStatus, setFilterStatus] = useState("All");

  const filteredTransactions = transactions.filter((transaction) => {
    return (
      (filterType === "All" || transaction.type === filterType) &&
      (filterStatus === "All" || transaction.status === filterStatus)
    );
  });

  // Function to get the status color
  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "green";
      case "Pending":
        return "yellow";
      case "Declined":
        return "red";
      default:
        return "inherit";
    }
  };

  return (
    <div className="transaction-method-container">
      <div className="filter-container">
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All Types</option>
          <option value="Deposit">Deposit</option>
          <option value="Withdrawal">Withdrawal</option>
        </select>

        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All Status</option>
          <option value="Approved">Approved</option>
          <option value="Pending">Pending</option>
          <option value="Declined">Declined</option>
        </select>
      </div>

      <table className="transaction-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Transaction Details</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.date}</td>
              <td>
                <div className="transaction-details">
                  <div className="transaction-details-top">
                    ID: {transaction.id} <br />
                    <strong>Sent to:</strong> {transaction.to.gcashNumber} -{" "}
                    {transaction.to.name}
                  </div>
                  <div className="transaction-details-bottom">
                    <strong>FROM GCASH:</strong> {transaction.from.gcashNumber}{" "}
                    <br />
                    Ref #: {transaction.refNumber}
                  </div>
                </div>
              </td>
              <td>{transaction.type}</td>
              <td>{transaction.amount}</td>
              <td style={{ color: getStatusColor(transaction.status) }}>
                {transaction.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Wallet;
