import "../PaymentPage.css";
import React, { useState, useEffect } from "react";
import ConfirmationPopup from "../pop-ups/Confirmation";
import GradientButton from "../../../components/Button/GradientButton";
import gcash from "../../../assets/Withdraw/gcash.svg";
import checkIcon from "../../../assets/Deposit/check.svg";
import copyIcon from "../../../assets/Deposit/copyyy.png"; // Import the copy icon
import CopyPopup from "../pop-ups/CopyPopup"; // Import the CopyPopup component

const Deposit = () => {
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [transactionId, setTransactionId] = useState("8455936");
  const [amount, setAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [senderNumber, setSenderNumber] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [error, setError] = useState("");
  const [senderError, setSenderError] = useState("");
  const [referenceError, setReferenceError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, "");

    if (numericValue === "" || isNaN(parseFloat(numericValue))) {
      setAmount("");
      setError("");
    } else {
      const parsedValue = parseFloat(numericValue);
      setAmount(numericValue);

      if (parsedValue < 99.9) {
        setError("Minimum deposit amount is ₱50.00");
      } else {
        setError("");
      }
    }
  };

  const handleSenderNumberChange = (e) => {
    const value = e.target.value;
    setSenderNumber(value);

    if (value.length !== 11 || !/^\d+$/.test(value)) {
      setSenderError("Sender number must be exactly 11 digits.");
    } else {
      setSenderError("");
    }
  };

  const handleReferenceNumberChange = (e) => {
    const value = e.target.value;
    setReferenceNumber(value);

    if (value.trim() === "") {
      setReferenceError("Reference number cannot be blank or spaces.");
    } else {
      setReferenceError("");
    }
  };

  const handleDepositClick = () => {
    if (amount < 99.9) {
      setError("Minimum deposit amount is ₱50.00");
      return;
    }
    setTransactionId("1234567");
    setShowConfirmationPopup(true);
  };

  const handleCloseConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  const handleAmountClick = (presetAmount) => {
    setAmount(`${presetAmount}.00`);
    setSelectedAmount(presetAmount);
    setError("");
  };

  const handleCopyAccountNumber = (accountNumber) => {
    navigator.clipboard
      .writeText(accountNumber)
      .then(() => {
        setShowCopyPopup(true);
      })
      .catch((err) => {
        console.error("Failed to copy account number: ", err);
      });
  };

  useEffect(() => {
    const isValidForm =
      amount >= 99.9 &&
      senderNumber.trim() !== "" &&
      referenceNumber.trim() !== "" &&
      senderError === "" &&
      referenceError === "";
    setIsButtonDisabled(!isValidForm);
  }, [amount, senderNumber, referenceNumber, senderError, referenceError]);

  return (
    <div className="payment-method-container">
      <h4 className="section-label">Deposit Details</h4>
      <div className="deposit-details-container">
        <div className="deposit-details">
          <div className="deposit-item">
            <img src={gcash} alt="GCash1" />
            <div>
              <p>
                Account Number: <strong>0926-5722-133</strong>
                <img
                  src={copyIcon}
                  alt="Copy"
                  className="copy-icon"
                  onClick={() => handleCopyAccountNumber("0926-5722-133")}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                />
              </p>
              <p>
                Account Name: <strong>No**aL</strong>
              </p>
            </div>
          </div>
          <div className="deposit-item">
            <img src={gcash} alt="GCash2" />
            <div>
              <p>
                Account Number: <strong>0926-5722-133</strong>
                <img
                  src={copyIcon}
                  alt="Copy"
                  className="copy-icon"
                  onClick={() => handleCopyAccountNumber("0926-5722-133")}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                />
              </p>
              <p>
                Account Name: <strong>No**aL</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="note">
          <strong>Note:</strong> <strong>Minimum 100 Pesos Cash In!</strong>
        </div>
        <ul className="note-list">
          <li>
            <img src={checkIcon} alt="Check" className="check-icon" />
            Before submitting your deposit request, please double-check the
            reference number and sender's details for quicker approval.
          </li>
          <li>
            <img src={checkIcon} alt="Check" className="check-icon" />
            Refresh this page before initiating any deposit transaction, as our
            GCash/Maya account information may be updated as needed.
          </li>
          <li>
            <img src={checkIcon} alt="Check" className="check-icon" />
            Please ensure that you deposit only to the GCash account listed on
            our official website.
          </li>
        </ul>
      </div>

      <h4 className="section-label">Deposit Form</h4>
      <div className="deposit-form-container">
        <div className="amount-buttons">
          {[100, 200, 500, 1000, 2500, 5000, 7500, 10000].map(
            (presetAmount) => (
              <button
                key={presetAmount}
                className={`amount-button ${
                  selectedAmount === presetAmount ? "selected" : ""
                }`}
                onClick={() => handleAmountClick(presetAmount)}
              >
                {presetAmount}
              </button>
            )
          )}
        </div>
        <div className="input-group">
          <label htmlFor="deposit-amount">Deposit Amount</label>
          <input
            type="text"
            id="deposit-amount"
            placeholder="₱0.00"
            value={amount}
            onChange={handleAmountChange}
          />
          {error && <span className="error-message">{error}</span>}
        </div>
        <div className="input-group">
          <label htmlFor="sender-number">
            Gcash number ( Number na ginamit)
          </label>
          <input
            type="text"
            id="sender-number"
            placeholder="0967XXXXXXX"
            value={senderNumber}
            onChange={handleSenderNumberChange}
          />
          {senderError && <span className="error-message">{senderError}</span>}
        </div>
        <div className="input-group">
          <label htmlFor="reference-number">Reference Number</label>
          <input
            type="text"
            id="reference-number"
            placeholder="XXXXXXXXXX"
            value={referenceNumber}
            onChange={handleReferenceNumberChange}
          />
          {referenceError && (
            <span className="error-message">{referenceError}</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="note">Note</label>
          <textarea id="note" placeholder="Enter note"></textarea>
        </div>
      </div>

      <GradientButton
        style={{
          marginTop: "16px",
          marginBottom: "16px",
          background: isButtonDisabled
            ? "#5B5B5B"
            : "linear-gradient(90deg, #FFF61F 27%, #7BF342 100%)",
          color: isButtonDisabled ? "#9F9F9F" : undefined,
          cursor: isButtonDisabled ? "not-allowed" : "pointer",
        }}
        text="DEPOSIT NOW"
        onClick={handleDepositClick}
        disabled={isButtonDisabled}
      />

      {showConfirmationPopup && (
        <ConfirmationPopup
          onClose={handleCloseConfirmationPopup}
          transactionId={transactionId}
          amount={amount}
          text="deposit"
        />
      )}
      <CopyPopup show={showCopyPopup} onClose={() => setShowCopyPopup(false)} />
    </div>
  );
};

export default Deposit;
