import React, { useEffect } from "react";
import { useAuthStore } from "./store/useAuthStore";
import AppRoutes from "./routes";

const App = () => {
  const loadAuthState = useAuthStore((state) => state.loadAuthState);

  useEffect(() => {
    loadAuthState(); // Load the authentication state from localStorage
  }, [loadAuthState]);

  return <AppRoutes />;
};

export default App;
