import React, { useEffect, useState } from "react";
import "./Race.css";
import Header from "../../components/Header/BalanceHeader";
import RaceStream from "../../components/RaceStream/RaceStream";
import RaceDetails from "../../components/RaceDetails/RaceDetails";
import RaceClosedBetting from "../../components/RaceDetails/RaceClosedBetting/RaceClosedBetting";
import RaceBetTypes from "../../components/RaceBetTypes/RaceBetTypes";
import RaceNumber from "../../components/RaceNumber/RaceNumber";
import RaceResult from "../../components/RaceResult/RaceResult";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import { useMediaQuery } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import RaceList from "../../components/RaceList/RaceList";
import BetModal from "../../components/BetModal/BetModal";
import useEventsStore from "../../store/useEventStore";
import LoadingPage from "../../components/LoadingState/Load";

const RacePage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  // const { latestEvent, fetchEvents } = useEventsStore();

  // const [selectedBetType, setSelectedBetType] = useState('DD'); // Default to DD
  // const [selectedRaceRange, setSelectedRaceRange] = useState(null);

  // useEffect(() => {
  //   fetchEvents(); // Fetch events on page load
  // }, [fetchEvents]);

  // useEffect(() => {
  //   if (latestEvent) {
  //     const defaultRaceRange = generateDefaultRaceRange(selectedBetType, latestEvent.expected_races);
  //     setSelectedRaceRange(defaultRaceRange);
  //   }
  // }, [latestEvent, selectedBetType]);

  // if (!latestEvent) {
  //   return <LoadingPage />;
  // }

  // const expectedRaces = latestEvent.expected_races;

  return (
    <>
      {isMobile ? <Header /> : <TopNavigation />}
      <div className="race-page-container">
        <div className="race-page">
          <RaceStream />
          <RaceClosedBetting />
          {/* <RaceDetails />
          <RaceBetTypes setSelectedBetType={setSelectedBetType} />
          <RaceNumber
            expectedRaces={expectedRaces}
            selectedBetType={selectedBetType}
            onRaceRangeSelect={setSelectedRaceRange}
          />
          <RaceResult selectedRaceRange={selectedRaceRange} />
          <RaceList />
          <BetModal selectedBetType={selectedBetType} /> */}
        </div>
      </div>
      {isMobile ? <BottomNavigation /> : <Footer />}
    </>
  );
};

// Helper function to generate default race range based on bet type
const generateDefaultRaceRange = (betType, expectedRaces) => {
  switch (betType) {
    case 'DD':
      return `1 & 2`;
    case 'FC':
      return `1`; // FC default to race 1
    case 'XD':
      return `1 & 3`;
    case 'DD_PLUS_1':
      return `1 - 3`;
    case 'PICK5':
      return expectedRaces >= 5 ? `1 - 5` : null;
    case 'PICK6':
      return expectedRaces >= 6 ? `1 - 6` : null;
    case 'WTA':
      return `1 - ${expectedRaces}`;
    default:
      return null;
  }
};

export default RacePage;
