// src/pages/LoginPage.jsx
import React from "react";
import Banner from "../../components/BannerSection/Banner.jsx";
import LoginSection from "../../components/LoginSection/LoginSection.jsx";
import "../../pages/Login/LoginPage.css";

function Login() {
  return (
    <div className="login-page">
      <div className="banner-half">
        <Banner />
      </div>

      <div className="login-half">
        <LoginSection />
      </div>
    </div>
  );
}

export default Login;
