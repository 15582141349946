import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import "./TopNavigation.css";
import kkLogo from "../../assets/TopNavigation/KK-logo.svg";
import profileIcon from "../../assets/TopNavigation/profile-icon.png";
import bettingRulesIcon from "../../assets/TopNavigation/bet-rules-icon.png";
import transactionHistoryIcon from "../../assets/TopNavigation/transact-icon.png";
import { useAuthStore } from "../../store/useAuthStore";
import { CurrencyFormatter } from "../../formatter/currencyFormatter";

const TopNavigation = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useAuthStore((state) => state.logout);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleSignOut = () => {
    logout();
    navigate("/login");
  };

  const handleClick = (path) => {
    navigate(path);
  };

  const handleRefresh = () => {
    window.location.reload(); // Refreshes the page
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={kkLogo} alt="Racing Royale Logo" className="logo-image" />
      </div>

      <div className="nav-actions">
        <div className="nav-links">
          <div
            onClick={() => handleClick("/")}
            className={`nav-link ${
              location.pathname === "/race" ? "active" : ""
            }`}
          >
            Race
          </div>
          <div
            onClick={() => handleClick("/payment")}
            className={`nav-link ${
              location.pathname === "/payment" ? "active" : ""
            }`}
          >
            Payment
          </div>
          <div
            onClick={() => handleClick("/results")}
            className={`nav-link ${
              location.pathname === "/results" ? "active" : ""
            }`}
          >
            Result
          </div>
          <div
            onClick={() => handleClick("/transaction")}
            className={`nav-link ${
              location.pathname === "/transaction" ? "active" : ""
            }`}
          >
            Transaction
          </div>
        </div>

        <div className="nav-items">
          <div className="user-info">
            Name: <span className="user-name">RJ Santos</span>
          </div>
          <div className="balance-info">
            Balance:{" "}
            <span className="balance-amount">
              {" "}
              <CurrencyFormatter value={1400} />
            </span>
          </div>

          {/* Refresh Icon */}
          <div className="nav-icon refresh-icon" onClick={handleRefresh}>
            &#x21bb;
          </div>

          <div className="account-dropdown" onClick={toggleDropdown}>
            <div className="nav-icon user-icon">&#x1F464;</div>{" "}
            {isDropdownOpen &&
              ReactDOM.createPortal(
                <div className="account-dropdown-menu">
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/account/profile")}
                  >
                    <img src={profileIcon} alt="Profile Icon" className="dropdown-icon" />
                    Profile
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/account/betting-rules")}
                  >
                    <img src={bettingRulesIcon} alt="Bet Rules Icon" className="dropdown-icon" />
                    Bet Rules
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/transaction")}
                  >
                    <img src={transactionHistoryIcon} alt="Transaction History Icon" className="dropdown-icon" />
                    Transaction History
                  </div>
                  <hr className="dropdown-divider" />
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/account/about")}
                  >
                    About Us
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/account/terms-condition")}
                  >
                    Terms & Conditions
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/account/private-policy")}
                  >
                    Privacy Policy
                  </div>
                  <hr className="dropdown-divider" />
                  <div className="dropdown-item logout" onClick={handleSignOut}>
                    Sign Out
                  </div>
                </div>,
                document.body
              )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNavigation;
